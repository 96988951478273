import { Instance, SnapshotOut, types } from 'mobx-state-tree'

export const AuthModel = types.model('Auth').props({
  accessToken: types.maybe(types.string),
  refreshToken: types.maybe(types.string),
  accessTokenExpiry: types.maybe(types.number),
  refreshTokenExpiry: types.maybe(types.number),
})

type AuthType = Instance<typeof AuthModel>
export interface Auth extends AuthType {}
type AuthSnapshotType = SnapshotOut<typeof AuthModel>
export interface AuthSnapshot extends AuthSnapshotType {}
export const createAuthDefaultModel = () => types.optional(AuthModel, {})
