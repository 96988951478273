import config from 'app/config'
import { Instance, SnapshotOut, types } from 'mobx-state-tree'

import { AuthModel, AuthSnapshot } from '../auth'
import { withEnvironment } from '../extensions/with-config'
import { UserModel, UserSnapshot } from '../user'
import retrieveAccessTokenAndUser from './lib/access-token-fetcher'
import generateLoginUrl from './lib/login-link-generator'
import createRefreshParams from './lib/refresh-param-creator'
import createRetrieveParams from './lib/retrieve-param-creator'
import { saveCredentials } from './lib/save-credentials'
import {
  accessTokenExpired,
  refreshTokenExpired,
} from './lib/token-expiry-determiner'

export const UserStoreModel = types
  .model('UserStore')
  .props({
    user: types.maybe(UserModel),
    auth: types.maybe(AuthModel),
    challenge: types.maybe(types.string),
    loginUrl: types.maybe(types.string),
    returnPath: types.maybe(types.string),
    loading: types.optional(types.boolean, false),
  })
  .extend(withEnvironment)
  .actions((self) => ({
    setLoading: (loading: boolean) => {
      self.loading = loading
    },
    setUser(user?: UserSnapshot) {
      self.user = user
    },
    setChallenge(challenge: string) {
      self.challenge = challenge
    },
    setLoginUrl: (url: string) => {
      self.loginUrl = url
    },
    setReturnPath: (path: string) => {
      self.returnPath = path
    },
    updateAuth(auth: Partial<AuthSnapshot>) {
      const {
        accessToken,
        refreshToken,
        accessTokenExpiry,
        refreshTokenExpiry,
      } = auth || {}

      const updatedState: Partial<AuthSnapshot> = {
        accessToken,
        refreshToken,
        accessTokenExpiry,
      }

      if (refreshTokenExpiry) {
        updatedState.refreshTokenExpiry = refreshTokenExpiry
      }

      self.auth = Object.assign({}, self.auth, updatedState)
    },
    logout() {
      self.user = undefined
      self.auth = undefined
      self.challenge = undefined
      self.loginUrl = undefined
    },
  }))
  .actions((self) => ({
    generateLoginUrl: () => {
      if (self.challenge && self.loginUrl) {
        return self.loginUrl
      }

      const { url, challenge } = generateLoginUrl(
        self.environment.config.memberfulOauthUrl,
        self.environment.config.clientId
      )

      self.setChallenge(challenge)
      self.setLoginUrl(url)

      return url
    },
    completeLogin: async (code: string) => {
      self.setLoading(true)
      const conf = self.environment.config || config

      const params = createRetrieveParams(conf, self as UserStore, code)

      try {
        const { auth, user } = await retrieveAccessTokenAndUser(
          self as UserStore,
          conf,
          params
        )

        self.updateAuth(auth)
        self.setUser(user)
        self.setLoading(false)
      } catch (error) {
        console.error('error getting token', error)
        self.logout()
      }

      if (typeof window !== 'undefined') {
        await saveCredentials(self as UserStore)
      }

      return self.user
    },
    refreshAccessToken: async () => {
      console.log('Refreshing access token')
      self.setLoading(true)
      const store = self as UserStore
      const conf = self.environment.config || config

      const params = createRefreshParams(conf, store)

      if (accessTokenExpired(store) && refreshTokenExpired(store)) {
        return self.logout()
      }

      if (!self.auth?.accessToken || !self.auth?.refreshToken) {
        return self.logout()
      }

      let auth, user

      try {
        const token = await retrieveAccessTokenAndUser(store, conf, params)

        auth = token.auth
        user = token.user

        self.updateAuth(auth)
        self.setUser(user)
        self.setLoading(false)
      } catch (error) {
        console.error('error refreshing token', error)
        self.logout()
        self.setLoading(false)
      }

      if (typeof window !== 'undefined') {
        await saveCredentials(self as UserStore)
      }

      return { auth, user } as { auth: AuthSnapshot; user: UserSnapshot }
    },
  }))

type UserStoreType = Instance<typeof UserStoreModel>
export interface UserStore extends UserStoreType {}
type UserStoreSnapshotType = SnapshotOut<typeof UserStoreModel>
export interface UserStoreSnapshot extends UserStoreSnapshotType {}
export const createUserStoreDefaultModel = () =>
  types.optional(UserStoreModel, {})
