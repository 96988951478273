import { User } from 'app/store/user'
import { gql } from 'graphql-request'

export const createUserQuery = () => gql`
  query getProfile {
    meMember {
      user {
        id
        email
        externalId
        avatar
        fullName
        dailyStreak
        quizStreak
        activatedAt
        overrideActivatedAt
        activeSubscription
        tier {
          name
        }
        numberComments
        lastOrderCost
        profileImage {
          url
          sizes {
            avatar {
              url
            }
          }
        }
        username
        phoneNumber
        birthday
        address {
          street
          city
          postalCode
          country
          state
        }
        planId
      }
    }

    profileStats {
      userPosition {
        score
        rank
      }
      userPercentile
      videosWatched
    }
  }
`

export const userQueryToUser = (data: any): User => {
  const { meMember } = data
  const activatedAt =
    meMember?.user.overrideActivatedAt || meMember?.user.activatedAt

  const profileImage =
    meMember?.user.profileImage?.sizes?.avatar?.url ||
    meMember?.user.profileImage?.url ||
    ''

  return {
    id: meMember?.user.id,
    externalId: meMember?.user.externalId,
    email: meMember?.user.email,
    fullName: meMember?.user.fullName,
    dailyStreak: meMember?.user.dailyStreak || 0,
    quizStreak: meMember?.user.quizStreak || 0,
    activatedAt: meMember?.user.activeSubscription ? activatedAt : undefined,
    subscribed: !!meMember?.user.activeSubscription,
    tier: meMember?.user.tier?.name || 'Initiate',
    comments: meMember?.user.numberComments || 0,
    lastOrderCost: meMember?.user.lastOrderCost || 0,
    profileImage,
    username: meMember?.user.username || '',
    phoneNumber: meMember?.user.phoneNumber || '',
    birthday: meMember?.user.birthday || '',
    address: {
      street: meMember?.user.address?.street || '',
      city: meMember?.user.address?.city || '',
      postalCode: meMember?.user.address?.postalCode || '',
      country: meMember?.user.address?.country || '',
      state: meMember?.user.address?.state || '',
    },
    userPosition: data.profileStats.userPosition?.rank || undefined,
    userScore: data.profileStats.userPosition?.score || undefined,
    userPercentile: data.profileStats.userPercentile || undefined,
    videosWatched: data.profileStats.videosWatched || undefined,
    planId: meMember?.user.planId || '',
  }
}
