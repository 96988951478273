import { DefaultSeo } from 'next-seo'

const config = {
  title: 'Mythical Society',
  fullTitle: 'Mythical Society',
  description:
    'The Mythical Society offers exclusive video content, behind-the-scenes access, unique collectibles, and more! Join the Mythical Society New Content Added Daily With over 150 hours of video including behind-the-scenes content, livestreams, podcasts, vlogs, archival rarities, and more, you will never run out of Mythicality.',
  themeColor: '#1E2807',
}

const GlobalMeta = () => {
  const { title, fullTitle, description, themeColor } = config
  const optional: { [key: string]: string } = {
    'application-name': fullTitle,
    viewport: 'width=device-width, initial-scale=1, viewport-fit=cover',
    'theme-color': themeColor,
  }

  return (
    <DefaultSeo
      titleTemplate={`%s | ${title}`}
      twitter={{
        cardType: 'summary_large_image',
      }}
      title={fullTitle}
      description={description}
      openGraph={{
        type: 'website',
        title: fullTitle,
        description: description,
        site_name: title,
        images: [
          {
            url: '/assets/meta/og-image.png',
            width: 400,
            height: 300,
            alt: 'Mythical Society',
          },
        ],
      }}
      additionalLinkTags={[
        ...[512, 192].map((size) => ({
          rel: 'apple-touch-icon',
          href: `/assets/meta/apple-touch-icon-${size}.png`,
          type: 'image/png',
          sizes: `${size}x${size}`,
        })),
        ...[16, 32].map((size) => ({
          rel: 'icon',
          type: 'image/png',
          sizes: `${size}x${size}`,
          href: `/assets/meta/favicon-${size}.png`,
        })),
        {
          rel: 'shortcut icon',
          href: '/assets/meta/favicon.ico',
        },
        {
          rel: 'manifest',
          href: '/manifest.json',
        },
      ]}
      additionalMetaTags={[
        ...Object.keys(optional).map((name) => {
          return {
            property: name,
            content: optional[name],
          }
        }),
      ]}
    />
  )
}

export default GlobalMeta
