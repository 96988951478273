import shallowObjectToQuery from 'app/lib/shallow-object-to-query'
import Base64 from 'crypto-js/enc-base64url'
import sha256 from 'crypto-js/sha256'

import generateRandomString from './random'

const generateLoginUrl = (
  baseAuthUrl: string,
  clientId = 'MjQpWdegmk5x9PtgPNKfgVy6'
) => {
  const retrieveQuery = () => ({
    response_type: 'code',
    client_id: clientId,
    state: 'authorized',
    code_challenge_method: 'S256',
    code_challenge: '',
  })

  const challenge = generateRandomString()

  const query = retrieveQuery()
  const codeChallenge = Base64.stringify(sha256(challenge))

  query.code_challenge = codeChallenge

  const queryString = shallowObjectToQuery(query)

  const url = `${baseAuthUrl}?${queryString}`
  return { url, challenge }
}

export default generateLoginUrl
