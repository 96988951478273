import IConfig from 'app/config/Config'

import { UserStore } from '..'

const createRetrieveParams = (
  config: IConfig,
  state: UserStore,
  code: string
) => {
  const codeVerifier = state.challenge

  return {
    code_verifier: codeVerifier,
    grant_type: 'authorization_code',
    client_id: `${config.clientId}`,
    code,
  }
}

export default createRetrieveParams
