import { Instance, SnapshotOut, types } from 'mobx-state-tree'

export const LastCollectionStoreModel = types
  .model('LastCollectionStore')
  .props({
    collectionType: types.maybe(types.string),
    collectionId: types.maybe(types.string),
    collectionName: types.maybe(types.string),
    collectionSlug: types.maybe(types.string),
    isPrimaryCollection: types.maybe(types.boolean),
  })
  .actions((self) => ({
    setCollectionType: (collectionType: string) => {
      self.collectionType = collectionType
    },
    setCollectionId: (collectionId: string) => {
      self.collectionId = collectionId
    },
    setCollectionName: (collectionName: string) => {
      self.collectionName = collectionName
    },
    setCollectionSlug: (collectionSlug: string) => {
      self.collectionSlug = collectionSlug
    },
    setIsPrimaryCollection: (isPrimaryCollection: boolean) => {
      self.isPrimaryCollection = isPrimaryCollection
    },
    reset: () => {
      self.collectionType = undefined
      self.collectionId = undefined
      self.collectionName = undefined
      self.collectionSlug = undefined
      self.isPrimaryCollection = undefined
    },
  }))

type LastCollectionStoreType = Instance<typeof LastCollectionStoreModel>
export interface LastCollectionStore extends LastCollectionStoreType {}
type LastCollectionStoreSnapshotType = SnapshotOut<
  typeof LastCollectionStoreModel
>
export interface LastCollectionStoreSnapshot
  extends LastCollectionStoreSnapshotType {}
export const createLastCollectionStoreDefaultModel = () =>
  types.optional(LastCollectionStoreModel, {})
