import IConfig from 'app/config/Config'
import createGraphqlClient from 'app/lib/graphql-client-creator'
import shallowObjectToQuery from 'app/lib/shallow-object-to-query'
import { createUserQuery, userQueryToUser } from 'app/queries/user/cms-user'

import { UserStore } from '..'
import { oneYearInMilliseconds, fifteenMinsInMilliseconds } from './token-expiry-determiner'

const req = {
  method: 'POST',
  timeout: 15000,
}

const retrieveAccessTokenAndUser = async (
  store: UserStore,
  config: IConfig,
  params: any
) => {
  // Retrieve access token
  const response = await fetch(
    `${config.memberfulOauthUrl}/token?${shallowObjectToQuery(params)}`,
    req
  )
  const body = await response.json()
  const {
    access_token: accessToken,
    refresh_token: refreshToken,
    expires_in,
  } = body

  let url = config.webCmsUrl || config.cmsUrl

  // if we are server side, use the cms url
  if (typeof window === 'undefined') {
    url = config.cmsUrl
  }

  // Retrieve user
  const query = createUserQuery()
  const client = createGraphqlClient(url, 'GET', store, accessToken)

  const abortController = new AbortController()

  setTimeout(() => {
    abortController.abort()
  }, config.timeout)

  const data = await client.request({
    document: query,
    signal: abortController.signal,
  })

  const user = userQueryToUser(data)

  const accessTokenExpiry = Date.now() + expires_in * 1000

  // difference between now and 15 minutes
  const timeDifference = fifteenMinsInMilliseconds - (accessTokenExpiry - Date.now())

  const refreshTokenExpiry = (Date.now() + oneYearInMilliseconds) - timeDifference

  const authData = {
    accessToken,
    accessTokenExpiry,
    refreshToken,
    refreshTokenExpiry,
  }

  if (params.refresh_token) {
    authData.refreshTokenExpiry = refreshTokenExpiry
    authData.refreshToken = params.refresh_token
  }

  return {
    auth: authData,
    user,
  }
}

export default retrieveAccessTokenAndUser
