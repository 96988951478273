import IConfig from 'app/config/Config'

import { UserStore } from '..'

const createRefreshParams = (config: IConfig, state: UserStore) => {
  const codeVerifier = state.challenge
  const refreshToken = state.auth && state.auth.refreshToken

  return {
    code_verifier: codeVerifier,
    grant_type: 'refresh_token',
    client_id: `${config.clientId}`,
    refresh_token: refreshToken,
  }
}

export default createRefreshParams
