const generateRandomString = () => {
  const array = new Uint32Array(64)
  crypto.getRandomValues(array)

  return Array.from(array, (dec) => ('0' + dec.toString(16)).substr(-2)).join(
    ''
  )
}

export default generateRandomString
