import { Instance, SnapshotOut, types } from 'mobx-state-tree'

export const UserModel = types.model('User').props({
  id: types.maybe(types.string),
  externalId: types.maybe(types.string),
  fullName: types.maybe(types.string),
  profileImage: types.maybe(types.string),
  dailyStreak: types.maybe(types.number),
  quizStreak: types.maybe(types.number),
  activatedAt: types.maybe(types.string),
  tier: types.maybe(types.string),
  subscribed: types.maybe(types.boolean),
  email: types.maybe(types.string),
  comments: types.maybe(types.number),
  lastOrderCost: types.maybe(types.number),
  username: types.maybe(types.string),
  phoneNumber: types.maybe(types.string),
  birthday: types.maybe(types.string),
  address: types.maybe(
    types.model('Address').props({
      street: types.maybe(types.string),
      city: types.maybe(types.string),
      postalCode: types.maybe(types.string),
      country: types.maybe(types.string),
      state: types.maybe(types.string)
    })
  ),
  userPosition: types.maybe(types.number),
  userScore: types.maybe(types.number),
  userPercentile: types.maybe(types.number),
  videosWatched: types.maybe(types.number),
  planId: types.maybe(types.string)
})

type UserType = Instance<typeof UserModel>
export interface User extends UserType {}
type UserSnapshotType = SnapshotOut<typeof UserModel>
export interface UserSnapshot extends UserSnapshotType {}
export const createUserDefaultModel = () => types.optional(UserModel, {})
