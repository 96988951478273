import { getSnapshot } from 'mobx-state-tree'

import { UserStore } from '../..'

const saveCredentials = async (store: UserStore) => {
  const snapshot = getSnapshot(store)

  await fetch('/api/auth/create-session', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(snapshot),
    credentials: 'include',
  })
}

export { saveCredentials }
