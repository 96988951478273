export const primaryColors = {
  primary1: '#FAF7EF',
  primary2: '#F5EEDF',
  primary3: '#F1E6CF',
  primary4: '#ECDDBF',
  primary5: '#E7D5AE',
  primary6: '#E2CC9E',
  primary7: '#DDC48E',
  primary8: '#D9BC7E',
  primary9: '#D4B36E',
  primary10: '#CFAB5E',
}

export const secondaryColors = {
  secondary1: '#F2F3F1',
  secondary2: '#E8E9E5',
  secondary3: '#DCDED9',
  secondary4: '#C6C8C0',
  secondary5: '#AFB2A7',
  secondary6: '#A4A89B',
  secondary7: '#5D6746',
  secondary8: '#3D4529',
  secondary9: '#2A3314',
  secondary10: '#1E2807',
}

export const greenColors = {
  green3: '#6EE7B7',
  green6: '#22C55E',
}

export const redColors = {
  red6: '#ef4444',
  red7: '#dc2626',
  red8: '#b91c1c',
  red9: '#991B1B',
  red10: '#7F1D1D',
}

export const orangeColors = {
  orange7: '#EA580C',
}

export const commonColors = {
  transparent: 'rgba(0, 0, 0, 0)',
  black: '#000000',
  white: '#FFFFFF',
  backdrop: 'rgba(0, 0, 0, 0.5)',
}
