function getEnv() {
  let env = ''
  const processEnv =
    process.env.NEXT_PUBLIC_VERCEL_ENV ||
    process.env.NODE_ENV ||
    process.env.DEV_CONFIG ||
    ''

  if (processEnv === 'production') {
    env = 'production'
  } else if (processEnv === 'staging') {
    env = 'staging'
  } else if (processEnv === 'phase2') {
    env = 'phase2'
  }

  return env
}

export default getEnv
