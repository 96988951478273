import { EnvConfigs } from 'app/config/Config'
import merge from 'lodash.merge'

import getEnv from '../lib/env-getter'

const devUrl = process.env.DEV_URL || 'http://localhost:8000'
const prodUrl =
  process.env.PROD_URL || 'https://mythical-society-backend.clock.uk'

const raw: EnvConfigs = {
  global: {
    clientId: 'uBVJLmA4rb9ncaXGnjqbJKgw',
    memberfulOauthUrl: 'https://mythicalsociety.memberful.com/oauth',
    memberfulGraphqlUrl:
      'https://mythicalsociety.memberful.com/api/graphql/member',
    memberfulUrl: 'https://mythicalsociety.memberful.com',
    cmsUrl: devUrl + '/api/graphql',
    cmsBaseUrl: devUrl,
    webMemberfulGraphqlUrl: '/api/graphql/member',
    webCmsUrl: '/api/graphql',
    siteUrl: 'https://mythical-society-frontend.vercel.app',
    firebaseWebConfig: {
      apiKey: 'AIzaSyAp74ZVKKbPnvWsCbrNXgP-lUCTQTTSBts',
      authDomain: 'mythical-dev-1027c.firebaseapp.com',
      projectId: 'mythical-dev-1027c',
      storageBucket: 'mythical-dev-1027c.appspot.com',
      messagingSenderId: '669754573659',
      appId: '1:669754573659:web:6bd1cf3431f8a3a8d5507e',
      measurementId: 'G-KMW1658CMC',
    },
    timeout: 15000,
  },
  staging: {
    clientId: 'Uy1zqpmt65u3KeU1E7DVcTKg',
    cmsUrl: 'https://mythical-society-backend-staging.clock.uk/api/graphql',
    cmsBaseUrl: 'https://mythical-society-backend-staging.clock.uk',
    siteUrl: 'https://mythical-society-frontend-staging.vercel.app',
  },
  phase2: {
    clientId: 'PwahXV8QARK492J1PY6u1Mwe',
    cmsUrl: 'https://mythical-society-backend-phase-2.clock.uk/api/graphql',
    cmsBaseUrl: 'https://mythical-society-backend-phase-2.clock.uk',
    siteUrl: 'https://mythical-society-frontend-phase-2.vercel.app',
  },
  production: {
    clientId: '6EiaK7BJXTY5aXDs3tuyFAcv',
    cmsUrl: prodUrl + '/api/graphql',
    cmsBaseUrl: prodUrl,
    siteUrl: 'https://www.mythicalsociety.com',
    firebaseWebConfig: {
      apiKey: 'AIzaSyBPCYLY8RPmDgM0NXIOZl21_baLLrnfcMQ',
      authDomain: 'mythical-society-app.firebaseapp.com',
      databaseURL: 'https://mythical-society-app.firebaseio.com',
      projectId: 'mythical-society-app',
      storageBucket: 'mythical-society-app.appspot.com',
      messagingSenderId: '728787272537',
      appId: '1:728787272537:web:e1c9d650d78dfed26e8248',
      measurementId: 'G-MQR7VB7FTB',
    },
  },
}

const config = merge(raw.global, raw[getEnv()] || {})
export default config
