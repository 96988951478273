import { Instance, SnapshotOut, cast, types } from 'mobx-state-tree'

/*
  id: string
  answers: {
    questionId: string
    answerIds: string[]
  }[]
  season?: string
*/

const AnswerModel = types.model('Answer', {
  questionId: types.string,
  answerIds: types.array(types.string),
})

type AnswerType = Instance<typeof AnswerModel>
export interface Answer extends AnswerType {}

export const QuizStoreModel = types
  .model('QuizStore')
  .props({
    id: types.optional(types.string, ''),
    answers: types.optional(types.array(AnswerModel), []),
    season: types.optional(types.string, ''),
    isLoggedOutSubmission: types.optional(types.boolean, false),
    closedApp: types.optional(types.boolean, false),
  })
  .actions((self) => ({
    setId(id: string) {
      self.id = id
    },
    setAnswers(answers: Answer[]) {
      self.answers = cast(answers)
    },
    setSeason(season: string) {
      self.season = season
    },
    setIsLoggedOutSubmission(isLoggedOutSubmission: boolean) {
      self.isLoggedOutSubmission = isLoggedOutSubmission
    },
    setClosedApp(closedApp: boolean) {
      self.closedApp = closedApp
    },
    reset() {
      self.id = ''
      self.answers = cast([])
      self.season = ''
      self.isLoggedOutSubmission = false
      self.closedApp = false
    },
  }))

type QuizStoreType = Instance<typeof QuizStoreModel>
export interface QuizStore extends QuizStoreType {}
type QuizStoreSnapshotType = SnapshotOut<typeof QuizStoreModel>
export interface QuizStoreSnapshot extends QuizStoreSnapshotType {}

export const quizStore = QuizStoreModel.create({})
