import type { UserStore } from 'app/store/user-store'
import { GraphQLClient } from 'graphql-request'
import { Platform } from 'react-native'

const createGraphqlClient = (
  url: string,
  method: 'GET' | 'POST',
  userStore?: Partial<UserStore>,
  token?: string,
  useJWT?: boolean
) => {
  const auth = userStore?.auth
  const user = userStore?.user

  const accessToken = token || (auth && auth.accessToken)
  const id = user && user.id

  const headers = {
    authorization: '',
    id: `${id || ''}`,
    'x-platform': Platform.OS,
  }

  if (accessToken) {
    headers.authorization = useJWT
      ? `JWT ${accessToken}`
      : `Bearer ${accessToken}`
  }

  const client = new GraphQLClient(url, { headers, method })

  return client
}

export default createGraphqlClient
