import { LastCollectionStoreModel } from 'app/store/last-collection-store'
import { UserStoreModel } from 'app/store/user-store'
import { Instance, SnapshotOut, types } from 'mobx-state-tree'
import { QuizStoreModel } from '../quiz-store'

/**
 * A RootStore model.
 */
// prettier-ignore
export const RootStoreModel = types.model("RootStore").props({
  userStore: types.optional(UserStoreModel, {} as any),
  lastCollectionStore: types.optional(LastCollectionStoreModel, {} as any),
  quizStore: types.optional(QuizStoreModel, {} as any),
  openedUrl: types.optional(types.string, ''),
}).actions(self => ({
  setOpenedUrl(url: string) {
    self.openedUrl = url
  },
}))

/**
 * The RootStore instance.
 */
export interface RootStore extends Instance<typeof RootStoreModel> {}

/**
 * The data of a RootStore.
 */
export interface RootStoreSnapshot extends SnapshotOut<typeof RootStoreModel> {}
