import { useCallback, useEffect } from 'react'
import { useRouter } from 'next/router'
import { isServerSide } from '@mythical/ui'

interface TrackedScrollPosition {
  [key: string]: {
    position: number
    timestamp: number
  }
}

const getScrollContainer = () => {
  if (isServerSide) return null
  // We use different scroll containers for mobile and desktop
  if (window.matchMedia('(max-width: 1020px)').matches) {
    return window
  } else {
    return document.getElementById('scroll-container')
  }
}

const getScrollPosition = (container?: Window | HTMLElement | null) => {
  if (!container) return 0
  if (container instanceof Window) {
    return container.scrollY
  }
  return container.scrollTop
}

export const useScrollRestoration = () => {
  const router = useRouter()

  const handleRouteChange = useCallback(async () => {
    const scrollContainer = getScrollContainer()
    const scrollPosition = getScrollPosition(scrollContainer)
    const currentPath = router.asPath
    const prevPositions = JSON.parse(
      sessionStorage.getItem('scrollPositions') || '{}'
    ) as TrackedScrollPosition

    // Set the positions but make sure to only keep the two latest paths
    const newPositions = {
      ...prevPositions,
      [currentPath]: {
        position: scrollPosition,
        timestamp: Date.now(),
      },
    } as TrackedScrollPosition

    const paths = Object.keys(newPositions)
    if (paths.length > 2) {
      const oldestPath = paths.reduce((oldestPath, path) => {
        // @ts-ignore
        if (newPositions[path].timestamp < newPositions[oldestPath].timestamp) {
          return path
        }
        return oldestPath
      }, paths[0] as string)
      delete newPositions[oldestPath]
    }

    // Save scroll position to sessionStorage
    sessionStorage.setItem('scrollPositions', JSON.stringify(newPositions))
  }, [router.asPath])

  useEffect(() => {
    router.events.on('routeChangeStart', handleRouteChange)
    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }
  }, [handleRouteChange, router.events])

  // Restore scroll position from sessionStorage
  useEffect(() => {
    const restoreScrollPosition = async () => {
      const scrollContainer = getScrollContainer()
      const savedScrollPositions = JSON.parse(
        sessionStorage.getItem('scrollPositions') || '{}'
      ) as TrackedScrollPosition

      if (!scrollContainer) return
      scrollContainer.scrollBy({
        top: savedScrollPositions[router.asPath]?.position || 0,
        left: 0,
      })
    }

    // Restore scroll position on route change
    router.events.on('routeChangeComplete', restoreScrollPosition)
    return () => {
      router.events.off('routeChangeComplete', restoreScrollPosition)
    }
  }, [router])
}
