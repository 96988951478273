import type { UserStore } from '..'

export const fifteenMinsInMilliseconds = 15 * 60 * 1000
export const oneYearInMilliseconds = 364 * 24 * 60 * 1000

const isTokenExpired = (
  store: UserStore,
  token: 'accessTokenExpiry' | 'refreshTokenExpiry'
) => {
  const authState = store.auth
  const tokenExpiry = authState && authState[token]

  const isExpired = tokenExpiry && Date.now() > tokenExpiry

  if (isExpired) return true
}

export const accessTokenExpired = (store: UserStore) =>
  isTokenExpired(store, 'accessTokenExpiry')

export const refreshTokenExpired = (store: UserStore) =>
  isTokenExpired(store, 'refreshTokenExpiry')
