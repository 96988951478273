import IConfig from 'app/config/Config'
import { onSnapshot } from 'mobx-state-tree'

import * as storage from '../../utils/storage'
import { Environment } from '../environment'
import { RootStoreModel, RootStore } from './root-store'

/**
 * The key we'll be saving our state as within async storage.
 */
const ROOT_STATE_STORAGE_KEY = 'root'

/**
 * Setup the root state.
 */
export async function setupRootStore(config: IConfig) {
  let rootStore: RootStore
  let data: any
  const environment = new Environment(config)

  try {
    // load data from storage
    data = (await storage.load(ROOT_STATE_STORAGE_KEY)) || {}

    rootStore = RootStoreModel.create(data, environment)
  } catch (e) {
    // if there's any problems loading, then let's at least fallback to an empty state
    // instead of crashing.
    rootStore = RootStoreModel.create({}, environment)

    let msg = ''

    if (typeof e === 'string') {
      msg = e
    } else if (e instanceof Error) {
      msg = e.message
    } else {
      msg = JSON.stringify(e)
    }

    // but please inform us what happened
    __DEV__ && console.error(msg, null)
  }

  // track changes & save to storage
  onSnapshot(rootStore, (snapshot) =>
    storage.save(ROOT_STATE_STORAGE_KEY, snapshot)
  )

  return rootStore
}
